import React, { useState, useEffect } from 'react';
import { Modal, Form, Toast, Input, Button, Swiper } from 'antd-mobile';
import axios from 'axios'
import './index.less';
import MobileNav from '../../../compontents/mobileNav';

function IndexContent(props) {
  const [form] = Form.useForm();

  const [time, setTime] = useState("")  //当前时间
  const [leaseShow, setLeaseShow] = useState(false);
  const [channel, setChannel] = useState(1);

  // const getNews = (category,pageNumber) =>{ 
  //   axios({
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     method: 'GET',
  //     url: `/consumer-zhifubao/news/center?newsCategory=${category}&pageSize=10&pageNumber=${pageNumber}`,
  //     responseType: 'json',
  //   }).then((res)=>{
  //     if(res?.data?.code===1){
  //       setNewsData(res.data.data.page.records)
  //     }
  //   })
  // }


  // const loadMore = () => {
  //   const num = parseInt(newsData.length/10)+1 
  //   axios({
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     method: 'GET',
  //     url: `/consumer-zhifubao/news/center?newsCategory=${tabActiveKey-1}&pageSize=10&pageNumber=${num}`,
  //     responseType: 'json',
  //   }).then((res)=>{
  //     if(res?.data?.code===1){
  //       setNewsData(...newsData, ...res.data.data.page.records)
  //       // setCurrent(res.data.data.page.current)
  //       // setTotal(res.data.data.page.total)
  //       // setLoading(false)
  //       // setHasMore(res.data.data.page.records.length > 0)
  //     }
  //   })
  // }

  const changeLeaseShow = () => {
    setLeaseShow(true)
  }

  const canleLeaseShow = () => {
    setLeaseShow(false)
  }

  const headerChangePages = (num) => {
    window.location.href = `${window.location.origin}${window.location.pathname}?flag=${num}`
  }

  const changePages = () => {
    const num = channel + 1
    window.open(`${window.location.origin}${window.location.pathname}?flag=${num}`)
  }

  // const showAsk = () => {
  //   Modal.show({
  //     content: (
  //       <div className="mobile-business-modal-content">
  //         <strong>立即咨询</strong>
  //         <div className="close" onClick={Modal.clear}></div>
  //         <img src="https://oss.huizustore.com/3ab3ba5267ca4fd688efe739112943ce.png" alt="huizu" className="ask-img" />
  //         <div className="ask-content">微信扫一扫，与客服经理一对一咨询开店</div>
  //       </div>
  //     ),
  //     closeOnMaskClick: true,
  //     className: "mobile-business-modal"
  //   })
  // }

  const showZulin = () => {
    Modal.show({
      content: (
        <div className="mobile-business-modal-content">
          <strong>立即租赁</strong>
          <div className="close" onClick={Modal.clear}></div>
          <img src="https://oss.huizustore.com/0c0f84be67b24fa890dbe7f13f6be59a.png" alt="huizu" className="ask-img" />
          <div className="ask-content">支付宝扫一扫，立即租赁</div>
        </div>
      ),
      closeOnMaskClick: true,
      className: "mobile-business-modal"
    })
  }

  const submitForm = (data) => axios({
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    url: '/consumer-zhifubao/aliPay/user/submitUserConsultInfo',
    responseType: 'json',
    data
  })

  const onFinish = (values) => {
    form.resetFields();
    submitForm({
      ...values,
      consultType: 1,
      consultSource: 1,
    }).then(res => {
      if (res?.data?.code === 1) {
        Toast.show({
          icon: 'success',
          content: res.data.msg,
        });
      } else {
        Toast.show({
          icon: 'fail',
          content: res.data.msg,
        });
      }
    });
  };

  //获取当前动态时间
  function getTime() {
    var myDate = new Date();
    var myYear = myDate.getFullYear(); //获取完整的年份(4位,1970-????)
    var myMonth = myDate.getMonth() + 1; //获取当前月份(0-11,0代表1月)
    var myToday = myDate.getDate(); //获取当前日(1-31)
    var myHour = myDate.getHours(); //获取当前小时数(0-23)
    var myMinute = myDate.getMinutes(); //获取当前分钟数(0-59)
    var mySecond = myDate.getSeconds(); //获取当前秒数(0-59)
    var nowTime;

    nowTime = `${myYear}年${fillZero(myMonth)}月${fillZero(myToday)}日  ${fillZero(myHour)}:${fillZero(myMinute)}:${fillZero(mySecond)}  `;
    setTime(nowTime)
  };

  function fillZero(str) {
    var realNum;
    if (str < 10) {
      realNum = '0' + str;
    } else {
      realNum = str;
    }
    return realNum;
  }
  setInterval(getTime, 1000);

  const [store, setStore] = useState('1000万+');
  useEffect(() => {
    let id = setInterval(() => {
      setStore(store => store + 1);
    }, 1000 * 60 * 60 * 24)
    return () => clearInterval(id)
  }, [store])

  return (
    <div className="mobileContent">
      <MobileNav />
      <Swiper indicatorProps={{style: {"--active-dot-color": '#ED0000', '--active-dot-size': '3px', '--active-dot-border-radius' : '50%'}}} allowTouchMove autoplay loop autoplayInterval={5000} className="homePage-swiper" >
        <Swiper.Item>
          <div className="mobileContent-container">
            <div className="mobileContent-container-header">
              <div className="mobileContent-container-header-top">
                全品类信用免押金租赁平台
              </div>
              <div className="mobileContent-container-header-center">
                为消费者和商家带来更高效更智能的租赁服务
              </div>
              <a className="mobileContent-container-header-bottom" href="#lianxi">
                <a href="#lianxi">商家入驻</a>
              </a>
            </div>
          </div>
        </Swiper.Item>
        <Swiper.Item>
          <div className="mobileContent-container" onClick={() => headerChangePages(2)}>
            <div className="mobileContent-container-header" id="business-header" style={{ cursor: 'pointer'}}>
              <div className="mobileContent-container-header-top">
                商家入驻
              </div>
              <div className="mobileContent-container-header-center">
                一分钟免费拥有自己的租赁店铺
              </div>
              <div className="mobileContent-container-header-bottom" href="lianxi">
                <a href="#lianxi">立即试用</a>
              </div>
            </div>
          </div>
        </Swiper.Item>
        <Swiper.Item>
          <div className="mobileContent-container" onClick={() => headerChangePages(3)}>
            <div className="mobileContent-container-header" id="miniprogram-header" style={{ cursor: 'pointer'}}>
              <div className="mobileContent-container-header-top">
                租赁小程序
              </div>
              <div className="mobileContent-container-header-center">
                您自己品牌的独立支付宝小程序
              </div>
              <div className="mobileContent-container-header-bottom" href="lianxi">
                <a href="#lianxi">立即咨询</a>
              </div>
            </div>
          </div>
        </Swiper.Item>
        <Swiper.Item>
          <div className="mobileContent-container" onClick={() => headerChangePages(4)}>
            <div className="mobileContent-container-header" id="platform-header" style={{ cursor: 'pointer'}}>
              <div className="mobileContent-container-header-top">
                开放平台
              </div>
              <div className="mobileContent-container-header-center">
                有效帮助商家快速甄别用户资质，有效把控风险，保证稳健经营
              </div>
              <div className="mobileContent-container-header-bottom" href="lianxi">
                <a href="#lianxi">立即咨询</a>
              </div>
            </div>
          </div>
        </Swiper.Item>
        <Swiper.Item>
          <div className="mobileContent-container" onClick={() => headerChangePages(5)}>
            <div className="mobileContent-container-header" id="lock-header" style={{ cursor: 'pointer'}}>
              <div className="mobileContent-container-header-top">
                锁机服务
              </div>
              <div className="mobileContent-container-header-center">
                多种业务模式均可支持
              </div>
              <div className="mobileContent-container-header-bottom" href="lianxi">
                <a href="#lianxi">立即咨询</a>
              </div>
            </div>
          </div>
        </Swiper.Item>
      </Swiper>
      <div className="mobileContent-body">
        {/* 数据展示部分 */}
        <div className="container1">
          {/* 数据展示部分 */}
          <div className="data-title">
            <div className="data-time">
              截止于
              <div>
                <div className="time">
                  {time.slice(0, 4)}<span>年</span>
                </div>
                <div className="time">
                  {time.slice(5, 7)}<span>月</span>
                </div>
                <div className="time">
                  {time.slice(8, 10)}<span>日</span>
                </div>
                <div className="time">
                  {time.slice(13, 15)}<span>时</span>
                </div>
                <div className="time">
                  {time.slice(16, 18)}<span>分</span>
                </div>
                <div className="time">
                  {time.slice(19, 21)}<span>秒</span>
                </div>
              </div>
            </div>
            <div className="data-datas">
              <div className="data-data" style={{ marginLeft: "0" }}>
                <span className="data-span">
                  <span className="data-span-font">2000<span className="data-span-unit">万+</span></span>
                  <span className="data-span1">用户数</span>
                </span>
              </div>
              <div className="data-data">
                <span className="data-span">
                  <span className="data-span-font">5000<span className="data-span-unit">+</span></span>
                  <span className="data-span1">商家入驻</span>
                </span>
              </div>
              <div className="data-data" id="month">
                <span className="data-span">
                  <span className="data-span-font">20<span className="data-span-unit">万+</span></span>
                  <span className="data-span1">月度订单</span>
                </span>
              </div>
              <div className="data-data" id="province">
                <span className="data-span">
                  <span className="data-span-font">20<span className="data-span-unit">+</span></span>
                  <span className="data-span1">覆盖省份</span>
                </span>
              </div>
              <div className="data-data">
                <span className="data-span">
                  <span className="data-span-font">10<span className="data-span-unit">万+</span></span>
                  <span className="data-span1">在租商品</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* 消费者业务 */}
        <div className="consumption">
          <div className="consumption-title">
            消费者业务
          </div>
          <hr className="hr-orange" />
          <div className="consumption-title-bottom">
            为消费者提供免押金租物服务，让生活更简单
          </div>
          <div className="consumption-content">
            <div className="consumption-item" id="video">
              <div className="consumption-item-title">宅家游戏电玩</div>
              <div className="consumption-item-btn" onClick={showZulin}>查看详情</div>
            </div>
            <div className="consumption-wide-content">
              <div className="consumption-wide-item" id="brand">
                <div className="consumption-wide-item-title">大牌手机抢先租</div>
                <div className="consumption-wide-item-btn" onClick={showZulin}>查看详情</div>
              </div>
              <div className="consumption-wide-item" id="camera">
                <div className="consumption-wide-item-title">摄影旅拍助手</div>
                <div className="consumption-wide-item-btn" onClick={showZulin}>查看详情</div>
              </div>
            </div>
            <div className="consumption-wide-content">
              <div className="consumption-wide-item" id="pad">
                <div className="consumption-wide-item-title">平板电脑合集</div>
                <div className="consumption-wide-item-btn" onClick={showZulin}>查看详情</div>
              </div>
              <div className="consumption-wide-item" id="watch">
                <div className="consumption-wide-item-title">耳机手表配饰</div>
                <div className="consumption-wide-item-btn" onClick={showZulin}>查看详情</div>
              </div>
            </div>
            <div className="consumption-item" id="computer">
              <div className="consumption-item-title">精选办公电脑</div>
              <div className="consumption-item-btn" onClick={showZulin}>查看详情</div>
            </div>
          </div>
          <div className="consumption-footer">全行业全场景助你业务升级</div>
          <div className="consumption-footer-info">只要能租的行业/产品，我们都为你提供解决</div>
        </div>
        {/* 三大核心产品 */}
        <div className="product">
          <div className="product-title" style={{ cursor: "pointer" }}>
            三大核心产品
          </div>
          <hr className="hr-orange" />
          <div className="product-title-bottom">
            一站式、支付、流量、免押金风控、司法，你需要的我们都有
          </div>
          <div className="product-icon" >
            <div className="product-icon-box" onMouseOver={() => setChannel(1)}>
              <div className={"product-icon-box-icon " + (channel === 1 ? "bussiness-active" : "bussiness")}></div>
              <div className="product-icon-box-name">商家入驻</div>
            </div>
            <div className="product-icon-box" onMouseOver={() => setChannel(2)}>
              <div className={"product-icon-box-icon " + (channel === 2 ? "mini-active" : "mini")}></div>
              <div className="product-icon-box-name">租赁小程序</div>
            </div>
            <div className="product-icon-box" onMouseOver={() => setChannel(3)}>
              <div className={"product-icon-box-icon " + (channel === 3 ? "open-active" : "open")}></div>
              <div className="product-icon-box-name">开放平台</div>
            </div>
          </div>
          <div className="product-content-store" id="storeContent" style={{ display: channel === 1 ? "block" : "none" }}>
            <div className="product-content-info">
              <span className="product-content-title">
              租赁商0元免费入驻
              </span>
              <ul className="product-contents">
                <li><div className="orangeCircle"></div> 0成本，1分钟免费快速开店</li>
                <li><div className="orangeCircle"></div> 线上，线下，微信，支付宝等流量全渠道通用</li>
                <li><div className="orangeCircle"></div> 导购选品，免押下单，租金扣款，逾期追款一站式闭环</li>
                <li><div className="orangeCircle"></div> 手机数码/母婴图书/服饰包箱/汽车电动车/美妆个护等全品类通用</li>
              </ul>
              <div className="product-button">
                <a className="product-button-left" href="#lianxi">
                  <a href="#lianxi">免费试用</a>
                </a>
                <div className="product-button-right">
                  <span onClick={changePages}>查看详情</span>
                </div>
              </div>
            </div>
          </div>
          <div className="product-content-store" style={{ display: channel === 2 ? "block" : "none" }} id="appContent">
            <div className="product-content-info">
              <span className="product-content-title">
              您自己的独立品牌
              </span>
              <ul className="product-contents">
                <li><div className="orangeCircle"></div> 基于云服务的支付宝小程序</li>
                <li><div className="orangeCircle"></div> 低成本享有租物流量红利</li>
                <li><div className="orangeCircle"></div> 无需自建研发团队，低成本快速拥有</li>
              </ul>
              <div className="product-button">
                <a className="product-button-left" href="#lianxi" >
                  <a href="#lianxi">免费试用</a>
                </a>
                <div className="product-button-right">
                  <span onClick={changePages}>查看详情</span>
                </div>
              </div>
            </div>
          </div>
          <div className="product-content-store" style={{ display: channel === 3 ? "block" : "none" }} id="openContent">
            <div className="product-content-info">
              <span className="product-content-title">
                更多功能供您使用
              </span>
              <ul className="product-contents">
                <li><div className="orangeCircle"></div> 多重技术能力输出</li>
                <li><div className="orangeCircle"></div> 有效赋能商家把控风险</li>
                <li><div className="orangeCircle"></div> 安全高效经营无忧</li>
              </ul>
              <div className="product-button">
                <div className="product-button-left" href="#lianxi" >
                  <a href="#lianxi">免费试用</a>
                </div>
                <div className="product-button-right">
                  <span onClick={changePages}>查看详情</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 四重保障部分 */}
        <div className="guarantee">
          <div className="guarantee-title">
            惠租四重保障
          </div>
          <hr className="hr-orange" />
          <div className="guarantee-title-bottom">
            全方位为你的资产保驾护航
          </div>
          <div className="guarantee-content">
            <div className="guarantee-item">
              <img src='https://oss.huizustore.com/9594f53b75ae45a0931ea20013c691d1.png' alt="img" className="guarantee-item-img" />
              <div className="guarantee-item-detail">
                <div className="guarantee-item-detail-title">免押风控</div>
                <div className="guarantee-item-detail-info">接入支付宝信用支付体系，为用户减免押金的同时解决商户的发货信任问题。结合惠租自研红盾风控，提供超1000条风控信息及AI人工建议，快速甄别用户资质，把控风险，稳健经营。</div>
              </div>
            </div>
            <div className="guarantee-item">
              <img src='https://oss.huizustore.com/67090c8c5b324f3fa8debc4a429a5655.png' alt="img" className="guarantee-item-img" />
              <div className="guarantee-item-detail">
                <div className="guarantee-item-detail-title">司法仲裁</div>
                <div className="guarantee-item-detail-info">与仲裁委系统级对接，案件证据一键提交审理，从提交至判决低至一个月时间内;同时可采用法院强制执行，失信人等多维司法途径保障资产安全。</div>
              </div>
            </div>
            <div className="guarantee-item">
              <img src='https://oss.huizustore.com/a658e609a70f4f41bd624b866105782d.png' alt="img" className="guarantee-item-img" />
              <div className="guarantee-item-detail">
                <div className="guarantee-item-detail-title">区块链及保险</div>
                <div className="guarantee-item-detail-info">永久存证，不可篡改；可购买人保，财产损失后保险赔偿。</div>
              </div>
            </div>
            <div className="guarantee-item">
              <img src='https://oss.huizustore.com/fcd729e333fe4262bf060a50e337d37b.png' alt="img" className="guarantee-item-img" />
              <div className="guarantee-item-detail">
                <div className="guarantee-item-detail-title">锁机</div>
                <div className="guarantee-item-detail-info">惠租推出的苹果锁机服务，贴合商家痛点，针对性地解决手机租赁逾期坏账问题。</div>
              </div>
            </div>
          </div>
        </div>
        {/* 联系我们 */}
        <div className="mobileContent-body-contact" id="lianxi">
          <div className="mobileContent-body-contact-title">
            联系我们
          </div>

          <Form name="basic" onFinish={onFinish} form={form} className="mobile-index-form">
            <Form.Item name="contactName" rules={[{ required: true, message: '您的姓名为必填' }]} style={{ marginTop: 30 }}>
              <div className="mobileContent-body-contact-input">
                <Input type="text" className="mobileContent-body-contact-input-style" placeholder="请输入您的姓名" />
              </div>
            </Form.Item>
            <Form.Item name="enterpriseName" rules={[{ required: true, message: '企业名称为必填' }]}>
              <div className="mobileContent-body-contact-input">
                <Input type="text" className="mobileContent-body-contact-input-style" placeholder="请输入企业名称" />
              </div>
            </Form.Item>
            <Form.Item name="telephone" rules={[{ required: true, message: '手机号码为必填' }]}>
              <div className="mobileContent-body-contact-input">
                <Input type="text" className="mobileContent-body-contact-input-style" placeholder="请输入手机号码" />
              </div>
            </Form.Item>
            <Form.Item name="categories">
              <div className="mobileContent-body-contact-input">
                <Input type="text" className="mobileContent-body-contact-input-style" placeholder="请输入意向类目，如：手机" />
              </div>
            </Form.Item>
            {/* <div className="form-button" onClick={onFinish}>
            提交信息
          </div> */}
            <Button type="submit" className="mobileContent-body-contact-button">提交信息</Button>
          </Form>

          <div className="mobileContent-body-contact-pic">
            <div className="mobileContent-body-contact-step">
              提交步骤
              <div>拨打咨询电话，立即</div>
              <div>领取开店大礼包</div>
            </div>
          </div>
          <div className="mobileContent-body-contact-phone">
            <div className="mobileContent-body-contact-phone-icon"></div>
            <span className="mobileContent-body-contact-phone-phoneNumber">0571—26881669</span>
            {/* &nbsp;&nbsp;&nbsp;&nbsp; */}
            <span className="mobileContent-body-contact-phone-phoneNumber">9:30—18:00</span>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default IndexContent;