import React from 'react';
import { Modal, Swiper, Form, Input, Button, Toast } from 'antd-mobile';
import axios from 'axios';
import './index.less';

function Lock(props) {

  const showAsk = () => {
    Modal.show({
      content: (
        <div className="mobile-business-modal-content">
          <strong>立即咨询</strong>
          <div className="close" onClick={Modal.clear}></div>
          <img src="https://oss.huizustore.com/3ab3ba5267ca4fd688efe739112943ce.png" alt="huizu" className="ask-img" />
          <div className="ask-content">微信扫一扫，与客服经理一对一咨询开店</div>
        </div>
      ),
      closeOnMaskClick: true,
      className: "mobile-business-modal"
    })
  }

  const showModal = () => {
    Modal.show({
      content: (
        <div className="mobile-business-modal-content">
          <strong>立即预约</strong>
          <div className="close" onClick={(e) => {
            Modal.clear()
          }}></div>
          <Form layout="horizontal" mode='card' onFinish={onFinish} footer={<Button block type="submit" color="danger" style={{ width: '50%', margin: '0 auto'}}>提交</Button>}>
            <Form.Item name="contactName" rules={[{required: true}]}>
              <span>姓<div style={{ opacity: '0', display: 'inline'}}>占位</div>名：</span>
              <Input placeholder='请输入姓名' />
            </Form.Item>
            <Form.Item name="enterpriseName" rules={[{required: true}]}>
              <span>企业名称：</span>
              <Input placeholder='请输入企业名称' />
            </Form.Item>
            <Form.Item name="telephone" rules={[{required: true}]}>
              <span>手机号码：</span>
              <Input placeholder='请输入手机号码' />
            </Form.Item>
            <Form.Item name="categories" rules={[{required: true}]}>
              <span>意向类型：</span>
              <Input placeholder='请输入意向类型' />
            </Form.Item>
          </Form>
        </div>
      ),
      closeOnMaskClick: true,
      className:"mobile-business-modal"
    })
  }

  const submitForm = (data) => axios({
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    url: '/consumer-zhifubao/aliPay/user/submitUserConsultInfo',
    responseType: 'json',
    data
  })

  const onFinish = (values) => {
    submitForm({
      ...values,
      consultType:1,
      consultSource: 1,
    }).then(res => {
      if (res?.data?.code === 1) {
        Modal.clear()
        Toast.show({
          icon: 'success',
          content: res.data.msg,
        })
      } else {
        Toast.show({
          icon: 'fail',
          content: res.data.msg,
        })
      }
    });
  };

  const headerChangePages = (num) => {
    window.location.href = `${window.location.origin}${window.location.pathname}?flag=${num}`
  }

  return (
    <div className="mobileContent">
      <Swiper defaultIndex={4} indicatorProps={{style: {"--active-dot-color": '#ED0000', '--active-dot-size': '3px', '--active-dot-border-radius' : '50%'}}} allowTouchMove autoplay loop autoplayInterval={5000} className="homePage-swiper" >
        <Swiper.Item>
          <div className="mobileContent-container" onClick={() => headerChangePages(1)}>
            <div className="mobileContent-container-header" style={{ cursor: 'pointer'}}>
              <div className="mobileContent-container-header-top">
                全品类信用免押金租赁平台
              </div>
              <div className="mobileContent-container-header-center">
                为消费者和商家带来更高效更智能的租赁服务
              </div>
              <div className="mobileContent-container-header-bottom" href="lianxi">
                <a href="#lianxi">商家入驻</a>
              </div>
            </div>
          </div>
        </Swiper.Item>
        <Swiper.Item>
          <div className="mobileContent-container" onClick={() => headerChangePages(2)} >
            <div className="mobileContent-container-header" id="business-header" style={{ cursor: 'pointer'}}>
              <div className="mobileContent-container-header-top">
                商家入驻
              </div>
              <div className="mobileContent-container-header-center">
                一分钟免费拥有自己的租赁店铺
              </div>
              <div className="mobileContent-container-header-bottom" href="lianxi">
                <a href="#lianxi">立即试用</a>
              </div>
            </div>
          </div>
        </Swiper.Item>
        <Swiper.Item>
          <div className="mobileContent-container" onClick={() => headerChangePages(3)}>
            <div className="mobileContent-container-header" id="miniprogram-header" style={{ cursor: 'pointer'}} >
              <div className="mobileContent-container-header-top">
                租赁小程序
              </div>
              <div className="mobileContent-container-header-center">
                您自己品牌的独立支付宝小程序
              </div>
              <div className="mobileContent-container-header-bottom" href="lianxi" >
                <a href="#lianxi">立即咨询</a>
              </div>
            </div>
          </div>
        </Swiper.Item>
        <Swiper.Item>
          <div className="mobileContent-container" onClick={() => headerChangePages(4)} >
            <div className="mobileContent-container-header" id="platform-header" style={{ cursor: 'pointer'}} >
              <div className="mobileContent-container-header-top">
                开放平台
              </div>
              <div className="mobileContent-container-header-center">
                有效帮助商家快速甄别用户资质，有效把控风险，保证稳健经营
              </div>
              <div className="mobileContent-container-header-bottom" href="lianxi">
                <a href="#lianxi">立即咨询</a>
              </div>
            </div>
          </div>
        </Swiper.Item>
        <Swiper.Item>
          <div className="mobileContent-container">
            <div className="mobileContent-container-header" id="lock-header">
              <div className="mobileContent-container-header-top">
                锁机服务
              </div>
              <div className="mobileContent-container-header-center">
                多种业务模式均可支持
              </div>
              <div className="mobileContent-container-header-bottom" href="lianxi" onClick={showAsk}>
                <a href="#lianxi">立即咨询</a>
              </div>
            </div>
          </div>
        </Swiper.Item>
      </Swiper>
      <div className="box" id="service">
        <div className="box-title">服务介绍</div>
        <div className="box-des">锁机服务带来的安心和保障，<br />对于店铺的营业额及发货量的提升效果显著</div>
        <div className="container">
          <img src="https://oss.huizustore.com/dcf8d661589741369ff95ad4657bedd8.png" alt="img" className="service-img" />
          <div className="service-content">此惠租平台的锁机服务精准切合商家痛点，监管机能够最大限度将撸机党、逾期、坏账问题降到最低。</div>
          <div className="service-btn" onClick={showModal}>免费试用</div>
        </div>
      </div>
      <div className="box" id="strength">
        <div className="box-title">服务优势</div>
        <div className="box-des">多种业务模式均可支持</div>
        <div className="container">
          <div className="shop-item">
            <img src="https://oss.huizustore.com/c9f5a13d9bd3498fa8c2580a040eb733.png" alt="img" className="shop-item-img" />
            <div className="shop-item-detail">
              <div className="shop-item-detail-title">发货无忧</div>
              <div className="shop-item-detail-content">锁机服务精准切合商家痛点，发货监管机可以最大限度将撸机党和逾期坏账问题降到最低。</div>
            </div>
          </div>
          <div className="shop-item">
            <img src="https://oss.huizustore.com/1187d4f71549490a8d129e22abace73f.png" alt="img" className="shop-item-img" />
            <div className="shop-item-detail">
              <div className="shop-item-detail-title">操作简单</div>
              <div className="shop-item-detail-content">四步简单配置，最快3个工作日完成锁机服务配置，线上进行管理和设置。</div>
            </div>
          </div>
          <div className="shop-item">
            <img src="https://oss.huizustore.com/c9f5a13d9bd3498fa8c2580a040eb733.png" alt="img" className="shop-item-img" />
            <div className="shop-item-detail">
              <div className="shop-item-detail-title">效益倍增</div>
              <div className="shop-item-detail-content">锁机服务带来的安心保障，店铺的营业额及发货量的提升效果显著。</div>
            </div>
          </div>
        </div>
      </div>


      <div className="box" id="flow">
        <div className="box-title">服务流程</div>
        <div className="box-des">采用按需购买锁机次数的形式，最快3-7个工作日就能完成<br />服务配置，线上进行锁机管理和设置</div>
        <div className="container">
          <div className="line">
            <div className="line-main"></div>
            <div className="line-item" id="one">
              <span></span>
            </div>
            <div className="line-item" id="two">
              <span></span>
            </div>
            <div className="line-item" id="three">
              <span></span>
            </div>
            <div className="line-item" id="four">
              <span></span>
            </div>
            <div className="line-item" id="five">
              <span></span>
            </div>
          </div>
          <div className="flow-item-list">
            <div className="flow-item">
              <div className="number">1</div>
              <div>
                <strong>购买服务</strong>
                <span>专业人员协助一对一开通</span>

              </div>
            </div>
            <div className="flow-item">
              <div className="number">2</div>
              <div>
                <strong>开通账户</strong>
                <span>快速分配独立MDM账号</span>
              </div>
            </div>
            <div className="flow-item">
              <div className="number">3</div>
              <div>
                <strong>设备配置</strong>
                <span>便捷配置独立监管锁</span>
              </div>
            </div>
            <div className="flow-item">
              <div className="number">4</div>
              <div>
                <strong>无忧发货</strong>
                <span>在线接单，快速无忧出货</span>
              </div>
            </div>
            <div className="flow-item">
              <div className="number">5</div>
              <div>
                <strong>锁机管理</strong>
                <span>服务限制/定位查看等一应俱全</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="box" id="operate">
        <div className="box-title">线上操作</div>
        <div className="box-des">线上设置上锁状态更方便</div>
        <div className="container">
          <div className="operate-item">
            <img src="https://oss.huizustore.com/a07cc8c84a85491dbcb14595b9a8a312.png" alt="icon" className="operate-item-icon" id="lock-img" />
            <div className="operate-item-title">上锁设备</div>
            <div className="operate-item-content">点击设备列表的丢失按钮，自定义提示文案，二次确认后即可进行锁机。</div>
          </div>
          <div className="operate-item">
            <img src="https://oss.huizustore.com/19dc76c46e914c2f902a7ca85f7fd6e6.png" alt="icon" className="operate-item-icon" id="unlock-img" />
            <div className="operate-item-title">解锁设备</div>
            <div className="operate-item-content">点击列表的找回按钮，二次确认后，完成设备的解锁。</div>
          </div>
          <div className="operate-item">
            <img src="https://oss.huizustore.com/0d24cbfc5fa64a9f8be4da227cf066f3.png" alt="icon" className="operate-item-icon" id="free-img" />
            <div className="operate-item-title">释放设备</div>
            <div className="operate-item-content">按顺序依次操作解除监管后，用户恢复出厂设置后即解除监管，完成设备释放。</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Lock;