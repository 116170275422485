import React, { useState, useEffect } from 'react';
import { Spin, Pagination, Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import './content.less'
import Footer from '../../../compontents/footer';

function NewsContent(props) {
  const [newsData, setNewsData] = useState([]);
  const [tabActiveKey, setTabActiveKey] = useState('1');
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => getNews(0, 1), [])

  const typelist = ['行业动态', '公司新闻', '商家案例', '媒体报道', '租机干货']
  const getNews = (category, pageNumber, searchTitle = undefined) => {
    setLoading(true)
    axios({
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'GET',
      url: searchTitle ? `/consumer-zhifubao/news/center?newsCategory=${category}&pageSize=3&pageNumber=${pageNumber}&searchTitle=${searchTitle}` : `/consumer-zhifubao/news/center?newsCategory=${category}&pageSize=3&pageNumber=${pageNumber}`,
      responseType: 'json',
    }).then((res) => {
      if (res?.data?.code === 1) {
        setNewsData(res.data.data.page.records)
        setCurrent(res.data.data.page.current)
        setTotal(res.data.data.page.total)
        setLoading(false)
      }
    }).finally(() => setLoading(false))
  }

  const pageChange = num => {
    getNews(tabActiveKey - 1, num)
  }

  const onTabChange = key => {
    setTabActiveKey(key)
    getNews(key - 1, 1)
  }

  const antIcon = <LoadingOutlined style={{ fontSize: 48, color: '#EB3A32' }} spin />;

  return (
    <div className='news'>
      <div className="pcContent-container" id="news-container">
        <div className="pcContent-container-header" id="news-header">
          <div className="title">新闻中心</div>
          <div className="input-box">
            <div className="icon"></div>
            <Input placeholder="您想要了解的行业动态" bordered={false} onPressEnter={(e) => {
              getNews(tabActiveKey - 1, 1, e.target.value)
            }} />
          </div>
        </div>
      </div>
      <div className="news_content">
        <Spin spinning={loading} indicator={antIcon}>
          <div className="news_content_left">
            <div className="news_content_left_title">首页 &gt; 新闻中心</div>
            <div className="news_content_left_list">
              {
                !!newsData.length && newsData.map(item =>
                  <a key={item.id} className='newsItem' href={item.url} target="_blank" rel="noreferrer">
                    <img src={item.pictureUrl} alt='' className="newsItem_img" />
                    <div className='newsItem_detail'>
                      <div>
                        <h6 className='newsItem_detail_title'><span className="newsItem_detail_type">{typelist[item.newsCategory]}</span>{item.title}</h6>
                        <div className='newsItem_detail_time'>{item?.updateTime ? item.updateTime : item.createTime}</div>
                      </div>
                      <div className='newsItem_detail_tag'>来源：{item.source}</div>
                    </div>
                  </a>
                )
              }
            </div>
          </div>
        </Spin>
        <div className="news_content_right">
          <div className="news_content_right_news">
            <div className="news_content_right_news_title">新闻中心</div>
            <ul>
              <li onClick={() => onTabChange(1)}>行业动态</li>
              <li onClick={() => onTabChange(2)}>公司新闻</li>
              <li onClick={() => onTabChange(3)}>商家案例</li>
              <li onClick={() => onTabChange(4)}>媒体报道</li>
              {/* <li onClick={() => onTabChange(5)}>租机干货</li> */}
            </ul>
          </div>
          <div className="news_content_right_tags">
            <div className="news_content_right_tags_title">标签</div>
            <div className="news_content_right_tags_list">
              {["风控", "支付宝", "租机干货", "免押金", "科技资讯", "数字化转型", "常见问题", "惠租云Saas", "平台活动", "招商入驻", "企业租赁", "红盾", "锁机", "租赁商"].map(item => <span className="tag_item" onClick={() => getNews(tabActiveKey - 1, 1, item)}>{item}</span>)}
            </div>
          </div>
        </div>
      </div>
      <Pagination className='newsPage' current={current} total={total} pageSize={3} onChange={pageChange}></Pagination>
      <Footer />
    </div>
  )
}

export default NewsContent;