import React, { useState } from 'react';
import { Popup, Collapse ,Modal } from 'antd-mobile';
import LeaseForm from './leaseForm';

import './index.less';
import './../../../App.css';

const Header = (props) =>{
    const [visible, setVisible] = useState(false)
    const [isModalLease, setisModalLease] = useState(false);
    const flag = window.location.search.split("=")[1]
    
    const cancleLease = () => {
        setisModalLease(false)
      }
    
    const showLease = () => {
        setisModalLease(true)
    }

    //获取子组件idList
    const getLeaseFormIdlist = (list)=> {
        return list.join(',');
    }

    //跳转页面
    const changePages = (num) => {
        window.location.href=`${window.location.origin}${window.location.pathname}?flag=${num}`
    }



    return (
        <div className="header">
           <div className='header-top'>
               <div className='header-top-icon'></div>
               <div className='header-top-hamburger' onClick={()=>setVisible(true)}></div>
               <Popup
               className='header-top-Popup'
                visible={visible}
                onClose={() => {
                    setVisible(false)
                }}
                bodyStyle={{ width: '100vw' }}
                position='left'
                showCloseButton
                >
                    <div　className='header-top-Popup-title' onClick={()=>changePages(1)}></div>
                    <Collapse 
                    className='header-top-Popup-fold' 
                    accordion
                    defaultActiveKey={['1']}>
                        <Collapse.Panel className='header-top-Popup-fold-item' key='1' title='B端服务'>
                            <Collapse>
                                <Collapse.Panel className='header-top-Popup-fold-item-sub' key='2' title="商家产品">
                                <div>
                                    <a className="header-top-Popup-button" style={{ backgroundColor: flag== 2 ? '#F9F8F8' : 'white'}} href={window.location.origin+window.location.pathname+'?flag=2'} >商家入驻</a>
                                    <a className="header-top-Popup-button" style={{ backgroundColor: flag== 3 ? '#F9F8F8' : 'white'}} href={window.location.origin+window.location.pathname+'?flag=3'} rel='noreferrer'>租赁小程序</a>
                                    <a className="header-top-Popup-button" style={{ backgroundColor: flag== 4 ? '#F9F8F8' : 'white'}} href={window.location.origin+window.location.pathname+'?flag=4'} rel='noreferrer'>开放平台</a>
                                </div>
                                </Collapse.Panel>
                            </Collapse>
                        </Collapse.Panel>
                        </Collapse>
                    <div className='header-top-Popup-button' style={{ backgroundColor: flag== 8 ? '#F9F8F8' : 'white'}} onClick={() => changePages(8)}>C端服务</div>
                    <div className='header-top-Popup-button' style={{ backgroundColor: flag== 9 ? '#F9F8F8' : 'white'}} onClick={() => changePages(9)}>企业租赁</div>
                    <div className='header-top-Popup-button' style={{ backgroundColor: flag== 5 ? '#F9F8F8' : 'white'}} onClick={() => changePages(5)}>锁机服务</div>
                    <div className='header-top-Popup-button' style={{ backgroundColor: flag== 6 ? '#F9F8F8' : 'white'}} onClick={()=>changePages(6)}>新闻中心</div>
                    <div className='header-top-Popup-button' style={{ backgroundColor: flag== 7 ? '#F9F8F8' : 'white'}} onClick={()=>changePages(7)}>关于我们</div>
                </Popup>
            </div> 
            
            <Modal
                visible={isModalLease}
                showCloseButton
                onClose={cancleLease}
                title='企业租赁'
                className="header-modal"
                content={<LeaseForm 
                    setisModalLease={setisModalLease}
                    getLeaseFormIdlist={getLeaseFormIdlist}
                />
                } 
            />
        </div>
    )
}

export default Header;