import React, { useState, useEffect } from 'react';
import axios from 'axios'
import './index.less'

function HeaderNews(props) {

  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    getNews()
    console.log(navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i));
  }, [])

  const headerChangePages = (num) => {
    window.location.href = `${window.location.origin}${window.location.pathname}?flag=${num}`
  }

  const getNews = (category, pageSize) => axios({
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'GET',
    url: `/consumer-zhifubao/news/center?newsCategory=0&pageSize=10&pageNumber=1`,
    responseType: 'json',
  }).then((res) => {
    if (res?.data?.code === 1) {
      setNewsData(res.data.data.opeNews)
    }
  })

  return (
    <div className="headerNews">
      <div className="headerNews-top">
        <div className="headerNews-top-img-box">
          <div className="headerNews-top-img-item">
            <img src="https://huizustore.com/static/media/%E6%B6%88%E8%B4%B9%E8%80%85%E4%B8%9A%E5%8A%A1%E4%BA%8C%E7%BB%B4%E7%A0%81.1dd1b5bf.png" alt="img" />
            <div className="headerNews-top-img-item-content">支付宝扫码</div>
          </div>
          <div className="headerNews-top-img-item">
            <img src="https://oss.huizustore.com/36f17009d7bd4817a78e25ca794a2d4b.png" alt="img" />
            <div className="headerNews-top-img-item-content">关注我们</div>
          </div>
        </div>
        <div className="headerNews-top-content-box">
          <img src="https://oss.huizustore.com/a31f653cc8214fefbc68afd828948c4c.png" alt="img" />
          <div className="headerNews-top-content-box-content">
            <div className="telephone">客服电话：0571—26881669</div>
            <div className="time">人工服务：9:30—18:00</div>
          </div>
        </div>
      </div>
      <div className="headerNews-footer">
        <div className="headerNews-footer-content-box">
          <div className="headerNews-footer-content-box-title">行业动态</div>
          <div className="headerNews-footer-content-box-content">
            {
              !!newsData.length && newsData.slice(0, 2).map(item => (
                <div className="news-item" key={item.id} onClick={() => window.open(item.url)}>
                  <span id="title">{item.title}</span>
                  <span id="time">{item.createTime.split(' ')[0].slice(5)}</span>
                </div>
              ))
            }
          </div>
        </div>
        <div className="headerNews-footer-content-box">
          <div className="headerNews-footer-content-box-title">媒体报道</div>
          <div className="headerNews-footer-content-box-content">
            {
              !!newsData.length && newsData.slice(2, 4).map(item => (
                <div className="news-item" key={item.id} onClick={() => window.open(item.url)}>
                  <span id="title">{item.title}</span>
                  <span id="time">{item.createTime.split(' ')[0].slice(5)}</span>
                </div>
              ))
            }
          </div>
        </div>
        <div className="headerNews-footer-more" onClick={() => headerChangePages(6)}>更多资料 &gt;</div>
      </div>
    </div>
  )
}

export default HeaderNews;