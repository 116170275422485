import React, { useState } from 'react';
import { Modal, Button } from 'antd-mobile';
import './index.less';

function Pc(props) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleClick = () => {
    setIsModalVisible(true)
  }
  const iconList = ['招商店铺入驻','商品管理','交易管理','风控管理','租后管理','财务运算']
  
  return (
    <div className="pc">
      <div className="pc-container">
        {/* <Header setFlag={props.setFlag} flag={props.flag} setUrl={props.setUrl} url={props.url} /> */}
        <div className="pc-container-header">
          <div className="pc-container-header-title">PC独立站/APP </div>
          <div className="pc-container-header-top" >
            建立您自己的租赁网站  <br/>APP其实很简单
          </div>
          <div className="pc-container-header-bottom" onClick={handleClick}>
           立即咨询
          </div>
          {/* <div className="pc-container-header-bottom" href="lianxi">
            <a href="#lianxi">商家入驻</a>
          </div> */}
          <div className="pc-container-header-mount"></div>
        </div>
        <div className='pc-container-pic'></div>
      </div>
      <div className="pc-body">
        <div className="pc-body-first">
            <div className="pc-body-title">PC独立站</div>
            <div className="pc-body-tip">适用于面向B端客户对企业租赁场景</div>
            <Button onClick={handleClick}>立即咨询</Button>
            <div className="pc-body-first-icon"></div>
        </div>
        <div className="pc-body-second">
            <div className="pc-body-title">快速建立你的APP</div>
            <div className="pc-body-tip">包含安卓和IOS版本，快速上架应用商场<br/>适用于各类租赁场景</div>
            <Button onClick={handleClick} >立即咨询</Button>
            <div className="pc-body-second-icon"></div>
        </div>
        <div className="pc-body-title">完美的一站式租赁流程</div>
        <div className="pc-body-third">
            {iconList.map((item)=>
              <div className='pc-body-third-icons'>
                  <div className='pc-body-third-icons-icon'></div>
                  <div className='pc-body-third-icons-tip'>{item}</div>
              </div>
              )}
        </div>
      </div>
      <div className="pc-service">
        <div className="pc-service-title">马上开始搭建您的线上店铺</div>
        <div className="pc-service-button"><Button onClick={handleClick}>立即咨询</Button></div>
      </div>
      
      <Modal
        visible={isModalVisible}
        //onCancel={handleCancel}
        footer={null}
        className="modalInquiry"
        getContainer={false}
        width={600}
        showCloseButton
        onClose={handleCancel}
        title='立即咨询'
        content={(
          <>
          <div className="pic-box">
            <div className="pic"></div>
            <div className="tip">微信扫一扫</div>
          </div>
          <div className="content">
            微信扫一扫与客服经理 <br />
            一对一沟通开店
          </div>
          </>
        )}
      />
      {/* <Footer /> */}
    </div>
  )
}

export default Pc;