import React from 'react';
import './index.less'
function ScrollNav(props) {

  return (
    <div className="fixBox">
        <div className="box-right">
          <div className="top"></div>
          <div className="box">
            <div className="tip">
              惠租客服
            </div>
          </div>
          <div className="lease">
            <div className="pic"></div>
            <div className="font">我要租赁</div>
            <div className="box-lease">
              <div className="title">立即租赁</div>
              <div className="pic"></div>
              <div className="tip">支付宝扫一扫，立即租赁</div>
            </div>
          </div>
          <div className='switch'>
          </div>
          <div className="wechat">
              <div className="pic"></div>
              <div className="font">微信咨询</div>
              <div className="box-wechat">
                <div className="title">微信咨询</div>
                <div className="tip">0成本快速开启你的租赁店铺</div>
                <div className="pic"></div>
                <div className="content">微信扫一扫，立即咨询开店</div>
              </div>
          </div>
          <div className="phone">
              <div className="pic"></div>
              <div className="font">电话咨询</div>
              <div className="box-phone">
                <div className="box-phone-title">客服热线</div>
                <div className="box-phone-phone">0571—26881669</div>
                <div className="box-phone-time">9:30-18:00</div>
              </div>
          </div>
        </div>
      </div>
  )
}

export default ScrollNav;