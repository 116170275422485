import React, { useState, useEffect } from 'react';
import './index.less'
import { Form, Button, Input, TextArea, Toast } from 'antd-mobile';
import axios from 'axios';

import computer from '../../../官网切图/qiyezuling/computer.png'
import deskbook from '../../../官网切图/qiyezuling/deskbook.png'
import print from '../../../官网切图/qiyezuling/print.png'
import phone from '../../../官网切图/qiyezuling/phone.png'
import notebook from '../../../官网切图/qiyezuling/notebook.png'
import otherservice from '../../../官网切图/qiyezuling/otherservice.png'

function Enterprise() {

  const [idList, setIdList] = useState([])

  useEffect(() => {

  }, [idList])


  const submitForm = (data) => axios({
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    url: '/consumer-zhifubao/aliPay/user/userEnterpriseLeaseApply/insertRecord',
    responseType: 'json',
    data
  })

  const onFinish = (values) => {
    submitForm({
      categoryNames: idList.join(','),
      customerRequirement: values?.customerRequirement ? values.customerRequirement : '',
      telephone: values?.telephone ? values.telephone : undefined,
      consultType: 2,
      consultSource: 1,
    }).then(res => {
      if (res?.data?.code === 1) {
        Toast.show({
          icon: 'success',
          content: res.data.msg,
        });

      } else {
        Toast.show({
          icon: 'fail',
          content: res.data.msg,
        });
      }
    });
  };


  //  改变选中类型
  const changeStyle = (title) => {
    // 定义空数组控制返回
    let newObjList = []
    if (idList.find(item => item === title)) {
      // 过滤id不相等数组
      let newObjList = idList.filter(val => val !== title)
      setIdList(newObjList)
      return
    }
    let newIdList = [...idList, title]

    setIdList(newIdList)
  }
  const picList = [

    {
      id: 1,
      title: "笔记本",
      url: computer,
    },
    {
      id: 2,
      title: "台式电脑",
      url: deskbook,
    }, {
      id: 3,
      title: "打印机",
      url: print,
    }, {
      id: 4,
      title: "手机",
      url: phone,
    }, {
      id: 5,
      title: "投影仪",
      url: notebook,
    },
    {
      id: 6,
      title: "其他",
      url: otherservice,
    }
  ]

  return (
    <div className="enterprise">
      <div className="enterprise-header">
        <div className="enterprise-container">
          <div className="enterprise-title">企业租赁</div>
          <div className="form" >
            <Form name="basic" className='form-content' onFinish={onFinish}>
              <div className='form-content-title'>您意向租赁的产品（可多选）</div>
              <div className='form-content-tunk'>
                {picList.map((item) =>
                  <div
                    key={item.id}
                    className={!!idList.length && idList.find(val => val === item.title) ? 'form-content-tunk-box box-hover' : 'form-content-tunk-box'}
                    onClick={() => changeStyle(item.title)}
                  >
                    <div className='form-content-tunk-box-pica' >
                      <img src={item.url} />
                    </div>
                    <div
                      className='form-content-tunk-box-item'
                    >

                      {item.title}
                    </div>
                  </div>
                )}
              </div>
              <div className='form-content-title' >其他要求（选填，可不填）</div>

              <Form.Item name="customerRequirement">
                <TextArea
                  showCount
                  rows={3}
                  maxLength={200}
                  placeholder="其他要求，例如新旧程度等；推荐方案免费咨询，不收取任何费用，快速配置最合适的配置方案。"
                />
              </Form.Item>
              <div className='form-content-bottom' >
                <div className='form-content-title' >联系电话：</div>
              </div>
              <Form.Item name='telephone' rules={[{ required: true, message: '联系方式为必填' }]}>
                <Input className='form-content-bottom-phone' placeholder='请输入手机号' required
                  onChange={(val) => console.log(val)}
                />
              </Form.Item>
              <div className='form-conten-btn'>
                <div className='form-conten-box'>
                  <Button block type='submit' color="danger" size="large">
                    提交委托
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Enterprise;