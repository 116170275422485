import React from 'react';
import './index.less'
import { Modal, Input, Form, Button, message } from 'antd';
import axios from 'axios';
function BusinessModal(props) {
  const { visible, handleCancel } = props

  const submitForm = (data) => axios({
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    url: '/consumer-zhifubao/aliPay/user/submitUserConsultInfo',
    responseType: 'json',
    data
  })

  const onFinish = (values) => {
    submitForm({
      ...values,
      consultType:1,
      consultSource: 1,
    }).then(res => {
      // console.log("666", res)
      if (res?.data?.code === 1) {
        handleCancel()
        message.success(res.data.msg);
      } else {
        message.error(res.data.msg);
      }
    });
  };

  return (
    <Modal
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        className="modalAppointment"
        getContainer={false}
        width={570}
      >
        <Form name="basic" onFinish={onFinish}>
          <div className="title">立即预约</div>
          <div className="form">
            <div className="form-div" style={{ marginTop: 20 }}>
              <span className="span" style={{ paddingRight: 5.97 }}>姓<span style={{visibility:"hidden"}}>姓名</span>名：</span>
              <Form.Item name="contactName" rules={[{ required: true, message: '您的姓名为必填' }]}>
                <div className="inputs" style={{ marginLeft: -5.97 }}>
                  <Input type="text" placeholder="请输入您的姓名" className="input" />
                </div>
              </Form.Item>
            </div>
            <div className="form-div">
              <span className="span">企业名称：</span>
              <Form.Item name="enterpriseName" rules={[{ required: true, message: '企业名称为必填' }]}>
                <div className="inputs">
                  <Input type="text" placeholder="请输入企业名称" className="input" />
                </div>
              </Form.Item>
            </div>
            <div className="form-div">
              <span className="span">手机号码：</span>
              <Form.Item name="telephone" rules={[{ required: true, message: '手机号码为必填' }]}>
                <div className="inputs">
                  <Input type="text" placeholder="请输入手机号码" className="input" />
                </div>
              </Form.Item>
            </div>
            <div className="form-div">
              <span className="span">意向类目：</span>
              <Form.Item name="categories">
                <div className="inputs">
                  <Input type="text" placeholder="请输入意向类目，如：手机" className="input" />
                </div>
              </Form.Item>
            </div>
          </div>
          <div className="buttonBox">
            <Button className="button" htmlType="submit">提交信息</Button>
          </div>
        </Form>

      </Modal>
  )
}

export default BusinessModal;