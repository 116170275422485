import React, { useState } from 'react';
// pc端
import Header from './pages/indexContent/header'
import IndexContent from './pages/indexContent';
import AbboutPage from './pages/otherPage/abboutPage';
import NewsPage from './pages/otherPage/NewsPage/content';
import MobileIndexContent from './pages/mobile/indexContent'
import MobileFooter from './pages/mobile/footer/index'
import MobileHeader from './pages/mobile/header'
import MobileNews from './pages/mobile/news/index'
import MobileAbout from './pages/mobile/aboutUs/index'
import MobileApp from './pages/mobile/theApp/index'
import MobileStore from './pages/mobile/theStore/index'
import MoblilePC from './pages/mobile/thePC/index'
import MobileBusiness from './pages/mobile/business';
import MobileMiniProgarm from './pages/mobile/miniProgaram';
import MobilePlatform from './pages/mobile/platform/index';
import MobileCustomer from './pages/mobile/customer/index';
import MobileEnterprise from './pages/mobile/enterprise/index';
import MobileLock from './pages/mobile/lock';
import PcBusiness from './pages/otherPage/business';
import PcMiniProgram from './pages/otherPage/miniProgram';
import PcPlatform from './pages/otherPage/platform';
import PcLock from './pages/otherPage/lock';

function Index() {

  const [flag, setFlag] = useState(parseInt(window.location.href.split("?flag=")[1]) || 1)
  const [url, setUrl] = useState('');

  return (
    <div>
      <link href="https://fonts.googleapis.com/css2?family=Oswald&display=swap" rel="stylesheet"></link>
      {navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i) ?
        (flag === 1 ? (
          // 惠租首页
          <div>
            <MobileHeader />
            <MobileIndexContent />
            <MobileFooter />
          </div>
        ) : (
          flag === 2 ? (
            <div>
              <MobileHeader setFlag={setFlag} flag={flag} setUrl={setUrl} url={url} />
              <MobileBusiness setFlag={setFlag} />
              <MobileFooter />
            </div>
          ) : (
            flag === 3 ? (
              <div>
                <MobileHeader setFlag={setFlag} flag={flag} setUrl={setUrl} url={url} />
                <MobileMiniProgarm setFlag={setFlag} />
                <MobileFooter />
              </div>
            ) : (
              flag === 4 ? (
                <div>
                  <MobileHeader setFlag={setFlag} flag={flag} setUrl={setUrl} url={url} />
                  <MobilePlatform setFlag={setFlag} />
                  <MobileFooter />
                </div>
              ) : (
                flag === 5 ? (
                  <div>
                    
                    <MobileHeader setFlag={setFlag} flag={flag} setUrl={setUrl} url={url} />
                    <MobileLock setFlag={setFlag} />
                    <MobileFooter />
                  </div>
                ) : flag === 6 ? (
                  <div>
                    <MobileHeader setFlag={setFlag} flag={flag} setUrl={setUrl} url={url} />
                    <MobileNews setFlag={setFlag} setUrl={setUrl} url={url} />
                    <MobileFooter />
                  </div>
                ) : flag === 7 ? (
                  <div>
                    <MobileHeader setFlag={setFlag} flag={flag} setUrl={setUrl} url={url} />
                    <MobileAbout setFlag={setFlag} />
                    <MobileFooter />
                  </div>
                ) : flag === 8 ? (
                  <div>
                    <MobileHeader setFlag={setFlag} flag={flag} setUrl={setUrl} url={url} />
                    <MobileCustomer setFlag={setFlag} />
                    <MobileFooter />
                  </div>
                ) : (
                  <div>
                    <MobileHeader setFlag={setFlag} flag={flag} setUrl={setUrl} url={url} />
                    <MobileEnterprise setFlag={setFlag} />
                    <MobileFooter />
                  </div>
                )
              )
            )
          )
        )
        )

        : (flag === 1 ? (
          <div>
            <Header setFlag={setFlag} flag={flag} setUrl={setUrl} url={url} />
            <IndexContent setFlag={setFlag} flag={flag} setUrl={setUrl} url={url} />
          </div>
        ) : (
          flag === 2 ? (
            <div>
              <Header setFlag={setFlag} flag={flag} setUrl={setUrl} url={url} />
              <PcBusiness setFlag={setFlag} />
            </div>
          ) : (
            flag === 3 ? (
              <div>
                <Header setFlag={setFlag} flag={flag} setUrl={setUrl} url={url} />
                <PcMiniProgram setFlag={setFlag} />
              </div>
            ) : (
              flag === 4 ? (
                <div>
                  <Header setFlag={setFlag} flag={flag} setUrl={setUrl} url={url} />
                  <PcPlatform setFlag={setFlag} />
                </div>
              ) : (
                flag === 5 ? (
                  <div>
                    <Header setFlag={setFlag} flag={flag} setUrl={setUrl} url={url} />
                    <PcLock setFlag={setFlag} />
                  </div>
                ) : flag === 6 ? (
                  <div>
                    <Header setFlag={setFlag} flag={flag} setUrl={setUrl} url={url} />
                    <NewsPage setFlag={setFlag} setUrl={setUrl} url={url} />
                  </div>
                ) : (
                  <div>
                    <Header setFlag={setFlag} flag={flag} setUrl={setUrl} url={url} />
                    <AbboutPage setFlag={setFlag} />
                  </div>
                )
              )
            )
          )
        )
        )
      }


    </div>
  )
}



export default Index;