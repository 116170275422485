import React from 'react';
import ScrollNav from '../../../compontents/scrollNav';
import './index.less'
function Customer(props) {

  return (
    <div className="customer ">
      <div className="customer-header">
        <div className="customer-container">
          <div className="customer-title">C端服务</div>
          <div className="customer-zfb">
            <img src="https://oss.huizustore.com/2d67e7e4b24946c68b50efc534f8099b.png" alt="img" />
          </div>
          <div className="customer-android">
            <img src="https://oss.huizustore.com/2e50234b88c4474c886da8f64dc3d447.png" alt="img" />
            <div>Android 客户端</div>
          </div>
          <div className="customer-ios">
            <img src="https://oss.huizustore.com/1115fb391e3740c393b4e60df784729d.png" alt="img" />
            <div>IOS 客户端</div>
            <span>(仅支持微信扫一扫)</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Customer;