import React, { useState } from 'react';
import Footer from '../../../compontents/footer';
import './index.less';
import HeaderNews from '../../../compontents/headerNews';
import Slider from '@ant-design/react-slick';
import BusinessModal from '../../../compontents/businessModal';
import { Carousel } from 'antd';

function AbboutPage(props) {

  const [isModalShow, setIsModalShow] = useState(false);
  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className='icon-left'
        onClick={onClick}
      >
      </div>
    );
  };

  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className='icon-right'
        onClick={onClick}
      >
      </div>
    );
  };

  const settings = {
    centerMode: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: 'linear',
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  const cancelModal = () => {
    setIsModalShow(false)
  }

  const showModal = () => {
    setIsModalShow(true)
  }

  const headerChangePages = (num) => {
    window.location.href = `${window.location.origin}${window.location.pathname}?flag=${num}`
  }

  return (
    <div className="abboutPage">
      <HeaderNews />
      <BusinessModal visible={isModalShow} handleCancel={cancelModal} />
      <Carousel dots={{className: 'swiper-dots'}} dotPosition="bottom" autoplay={true} autoplaySpeed={5000} >
        <div className="pcContent-container" onClick={() => headerChangePages(1)}>
          <div className="pcContent-container-header" id="total-header" style={{ cursor: 'pointer'}}>
            <div className="container">
              <div className="pcContent-container-header-top">
                全品类信用免押金租赁平台
              </div>
              <div className="pcContent-container-header-center">
                为消费者和商家提供更高效更智能的租赁服务
              </div>
              <div className="pcContent-container-header-bottom" href="lianxi">
                <a href="#lianxi">商家入驻</a>
              </div>
            </div>
          </div>
        </div>
        <div className="pcContent-container"  onClick={() => headerChangePages(2)}>
          <div className="pcContent-container-header" id="business-header" style={{ cursor: 'pointer'}}>
            <div className="container">
              <div className="pcContent-container-header-top">
                商家入驻
              </div>
              <div className="pcContent-container-header-center">
                一分钟免费拥有自己的租赁店铺
              </div>
              <div className="pcContent-container-header-bottom" href="lianxi">
                <a href="#lianxi">立即试用</a>
              </div>
            </div>
          </div>
        </div>
        <div className="pcContent-container" onClick={() => headerChangePages(3)}>
          <div className="pcContent-container-header" id="program-header" style={{ cursor: 'pointer'}}>
            <div className="container">
              <div className="pcContent-container-header-top">
                租赁小程序
              </div>
              <div className="pcContent-container-header-center">
                您自己品牌的独立支付宝小程序
              </div>
              <div className="pcContent-container-header-bottom" href="lianxi">
                <a href="#lianxi">立即咨询</a>
              </div>
            </div>
          </div>
        </div>
        <div className="pcContent-container" onClick={() => headerChangePages(4)}>
          <div className="pcContent-container-header" id="platform-header" style={{ cursor: 'pointer'}}>
            <div className="container">
              <div className="pcContent-container-header-top">
                开放平台
              </div>
              <div className="pcContent-container-header-center">
                有效帮助商家快速甄别用户资质，有效把控风险，<br />保证稳健经营。
              </div>
              <div className="pcContent-container-header-bottom" href="lianxi">
                <a href="#lianxi">立即咨询</a>
              </div>
            </div>
          </div>
        </div>
        <div className="pcContent-container" onClick={() => headerChangePages(5)}>
          <div className="pcContent-container-header" id="lock-header" style={{ cursor: 'pointer'}}>
            <div className="container">
              <div className="pcContent-container-header-top">
                锁机服务
              </div>
              <div className="pcContent-container-header-center">
                多种业务模式均可支持
              </div>
              <div className="pcContent-container-header-bottom" href="lianxi">
                <a href="#lianxi">立即咨询</a>
              </div>
            </div>
          </div>
        </div>
      </Carousel>
      
      <div className="about">
        <img src="https://oss.huizustore.com/0ac8ddba550440ca9eed61dad34e38df.png" alt="company" className="about-img" />
        <div className="about-detail">
          <div className="about-detail-title">关于我们</div>
          <div className="about-detail-describe">领先的数字化租赁交易服务提供商</div>
          <div className="about-detail-info">惠租 (vww.huizustore.com)是国内领先的头部信用免押租赁平台，为企业和个人提供一站式租赁服务。惠租全目前已服务超2000万用户，5000十租货商户入驻，覆盖全国超25个省份、碧盖城市超200个，累计租出数十万设备，累计为用户减免押金超百亿。惠租始终将服务好用户作为发展的使命，持续帮助商家进行线上线下协同，连接线下实体店和B端、C端用户，提供更加优质的服务环境</div>
        </div>
      </div>
      <div className="boss">
        <div className="boss-title">我们的团队</div>
        <div className="boss-describe">脚本科技（惠租）成立于2019年，创始人成员来自阿里巴巴、支付宝、淘宝、美团等，拥有超过10年的交易及营销平台产品经验。借力于共享经济的蓬勃发展，惠租致力于为消费者提供循环租赁服务，助力低碳环保的绿色消费理念。</div>
        <div className="boss-list">
          <div className="boss-item">
            <img src="https://oss.huizustore.com/c148a9941e9c491baf6050058bc80b51.png" alt="boss-img" className="boss-item-img" />
            <div className="boss-item-name">李昱龙</div>
            <div className="boss-item-position">创始人CEO</div>
            <div className="boss-item-info">原支付宝交易线产品经理；电子科技大学硕士，有金融学和电子工程双学位。</div>
          </div>
          <div className="boss-item">
            <img src="https://oss.huizustore.com/13dd313253044acdb68370a9f7cdaa45.png" alt="boss-img" className="boss-item-img" />
            <div className="boss-item-name">杨代明</div>
            <div className="boss-item-position">联合创始人</div>
            <div className="boss-item-info">原百度产品专家，电子科技大学硕士，有工商管理和电子工程双学位。</div>
          </div>
          <div className="boss-item">
            <img src="https://oss.huizustore.com/cdcd4b2c90b94108875885311ac39a50.png" alt="boss-img" className="boss-item-img" />
            <div className="boss-item-name">王国丰</div>
            <div className="boss-item-position">研发总监</div>
            <div className="boss-item-info">原滴滴架构师，魔筷科技交易线技术负责人，郑州大学学士。</div>
          </div>
        </div>
      </div>
      <div className="gallery">
        <div className="gallery_title">相关荣誉</div>
        <div className="gallery_describe">公司已获得国家高新技术企业、杭州市人工智能小镇项目、梦想小镇优质项目、杭州市雏鹰计划等荣誉认证。</div>
        <div className="gallery_content">
          <div className="slider-container">
            <Slider {...settings}>
              <div className="img-box">
                <img src="https://oss.huizustore.com/b24a35935a45446a8c0b67b84e95175e.png" alt="hornor" width={198} height={282}  />
              </div>
              <div className="img-box">
                <img src="https://oss.huizustore.com/03b5c5f8311a4de7991f5c2376fcfe93.png" alt="hornor" width={248} height={171}  />
              </div>
              <div className="img-box">
                <img src="https://oss.huizustore.com/19a666919caf42129838202fb0022840.png" alt="hornor" width={198} height={282} />
              </div>
              <div className="img-box">
                <img src="https://oss.huizustore.com/5156d3232d914d82af66108b352edc47.png" alt="hornor" width={248} height={171}  />
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <div className="huizu">
        <div className="huizu_title">在惠租</div>
        <div className="huizu_describe">让天下的店铺都能做租赁 让租赁没有押金</div>
        <div className="huizu_content">
          <div className="huizu_content_left">
            <div className="huizu_content_left_item">
              <div className="huizu_content_left_item_title">租赁用户在惠租</div>
              <div className="huizu_content_left_item_info">惠租APP及支付宝“惠租”小程序，可以租赁到100＋种类的产品，包括电脑、手机、平板、品牌手表、无人机等。惠租平台真正做到信用免押，随租随用，正品速发，在租前、租中、租后全流程跟进服务，获得真实好用的的租赁商品，大幅节省租赁成本支出，上惠租好平台真无忧。</div>
            </div>
            <div className="huizu_content_left_item">
              <div className="huizu_content_left_item_title">租赁商在惠租</div>
              <div className="huizu_content_left_item_info">脚本科技旗下拥有惠租及惠租云SaaS，分别为租赁商提供入驻和自建租赁小程序产品，基于长期沉淀的金融级租赁交易能力，为线上线下租赁商提供完整信用租赁解决方案，让天下的店铺都能做租赁，让租赁没有押金。</div>
            </div>
          </div>
          <div className="huizu_content_right">
            <img src="https://oss.huizustore.com/e93c75340c07479f995f85152209a076.png" alt="huizu" />
          </div>
        </div>
      </div>
      <div className="value">
        <div className="value_title">我们的价值观</div>
        <div className="value_describe">让天下的店铺都能做租赁，让租赁没有押金</div>
        <div className="value_content">
          <div className="value_item">
            <div className="value_item_title">宗旨</div>
            <div className="value_item_info">引领新租赁赛道的创新和发展</div>
          </div>
          <div className="value_item">
            <div className="value_item_title">理念</div>
            <div className="value_item_info">消费新选择，信用免押金，让租赁更简单</div>
          </div>
          <div className="value_item">
            <div className="value_item_title">价值</div>
            <div className="value_item_info">为线上线下租赁商提供完整信用租赁解决方案</div>
          </div>
          <div className="value_item">
            <div className="value_item_title">使命</div>
            <div className="value_item_info">帮助天下租赁商和消费者享受更智能的租赁新生活</div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default AbboutPage;
