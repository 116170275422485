import React, { useState } from 'react';
import { Modal, Form, Input, message, Carousel } from 'antd';
import axios from 'axios'
import Footer from '../../compontents/footer';
import './index.less';
import HeaderNews from '../../compontents/headerNews/index';
import ScrollNav from '../../compontents/scrollNav';

function IndexContent(props) {

  const [form] = Form.useForm();

  const [time, setTime] = useState()  //当前时间
  const [leaseShow, setLeaseShow] = useState(false);
  const [channel, setChannel] = useState(1);

  const changeLeaseShow = () => {
    setLeaseShow(true)
  }

  const canleLeaseShow = () => {
    setLeaseShow(false)
  }

  const changePages = () => {
    const num = channel + 1
    window.open(`${window.location.origin}${window.location.pathname}?flag=${num}`)
  }

  const headerChangePages = (num) => {
    window.location.href = `${window.location.origin}${window.location.pathname}?flag=${num}`
  }

  const submitForm = (data) => axios({
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    url: '/consumer-zhifubao/aliPay/user/submitUserConsultInfo',
    responseType: 'json',
    data
  })

  const onFinish = (values) => {
    form.resetFields();
    submitForm({
      ...values,
      consultType: 1,
      consultSource: 1,
    }).then(res => {
      if (res?.data?.code === 1) {
        message.success(res.data.msg);
      } else {
        message.error(res.data.msg);
      }
    });
  };

  //获取当前动态时间
  function getTime() {
    var myDate = new Date();
    var myYear = myDate.getFullYear(); //获取完整的年份(4位,1970-????)
    var myMonth = myDate.getMonth() + 1; //获取当前月份(0-11,0代表1月)
    var myToday = myDate.getDate(); //获取当前日(1-31)
    var myHour = myDate.getHours(); //获取当前小时数(0-23)
    var myMinute = myDate.getMinutes(); //获取当前分钟数(0-59)
    var mySecond = myDate.getSeconds(); //获取当前秒数(0-59)
    var nowTime;

    // nowTime = myYear + '年' + fillZero(myMonth) + '月' + fillZero(myToday) + '日' + '  ' + fillZero(myHour) + ':' +
    //   fillZero(myMinute) + ':' + fillZero(mySecond) + '  ';
    nowTime = `${myYear}年${fillZero(myMonth)}月${fillZero(myToday)}日  ${fillZero(myHour)}:${fillZero(myMinute)}:${fillZero(mySecond)}  `;
    // console.log("时间", nowTime);
    setTime(nowTime)
    // $('#time').html(nowTime);
  };

  function fillZero(str) {
    var realNum;
    if (str < 10) {
      realNum = '0' + str;
    } else {
      realNum = str;
    }
    return realNum;
  }
  setInterval(getTime, 1000);

  return (
    <div className="indexContent">
      <ScrollNav />
      <HeaderNews />
      <Carousel dots={{className: 'swiper-dots'}} dotPosition="bottom" autoplay={true} autoplaySpeed={5000} >
        <div className="pcContent-container">
          <div className="pcContent-container-header" id="total-header">
            <div className="container">
              <div className="pcContent-container-header-top">
                全品类信用免押金租赁平台
              </div>
              <div className="pcContent-container-header-center">
                为消费者和商家提供更高效更智能的租赁服务
              </div>
              <div className="pcContent-container-header-bottom" href="lianxi">
                <a href="#lianxi">商家入驻</a>
              </div>
            </div>
          </div>
        </div>
        <div className="pcContent-container"  onClick={() => headerChangePages(2)}>
          <div className="pcContent-container-header" id="business-header" style={{ cursor: 'pointer'}}>
            <div className="container">
              <div className="pcContent-container-header-top">
                商家入驻
              </div>
              <div className="pcContent-container-header-center">
                一分钟免费拥有自己的租赁店铺
              </div>
              <div className="pcContent-container-header-bottom" href="lianxi">
                <a href="#lianxi">立即试用</a>
              </div>
            </div>
          </div>
        </div>
        <div className="pcContent-container" onClick={() => headerChangePages(3)}>
          <div className="pcContent-container-header" id="program-header" style={{ cursor: 'pointer'}}>
            <div className="container">
              <div className="pcContent-container-header-top">
                租赁小程序
              </div>
              <div className="pcContent-container-header-center">
                您自己品牌的独立支付宝小程序
              </div>
              <div className="pcContent-container-header-bottom" href="lianxi">
                <a href="#lianxi">立即咨询</a>
              </div>
            </div>
          </div>
        </div>
        <div className="pcContent-container" onClick={() => headerChangePages(4)}>
          <div className="pcContent-container-header" id="platform-header" style={{ cursor: 'pointer'}}>
            <div className="container">
              <div className="pcContent-container-header-top">
                开放平台
              </div>
              <div className="pcContent-container-header-center">
                有效帮助商家快速甄别用户资质，有效把控风险，<br />保证稳健经营。
              </div>
              <div className="pcContent-container-header-bottom" href="lianxi">
                <a href="#lianxi">立即咨询</a>
              </div>
            </div>
          </div>
        </div>
        <div className="pcContent-container" onClick={() => headerChangePages(5)}>
          <div className="pcContent-container-header" id="lock-header" style={{ cursor: 'pointer'}}>
            <div className="container">
              <div className="pcContent-container-header-top">
                锁机服务
              </div>
              <div className="pcContent-container-header-center">
                多种业务模式均可支持
              </div>
              <div className="pcContent-container-header-bottom" href="lianxi">
                <a href="#lianxi">立即咨询</a>
              </div>
            </div>
          </div>
        </div>
      </Carousel>
      <div className="container1">
        {/* 数据展示部分 */}
        <div className="data-title">
          <div className="data-time">
            截止于：{time}
          </div>
          <div className="data-datas">
            <div className="data-data" style={{ marginLeft: "0" }}>
              <span className="data-span">
                <span className="data-span-font">2000<span className="data-span-unit">万+</span></span>
                <span className="data-span1">用户数</span>
              </span>
            </div>
            <div className="data-data">
              <span className="data-span">
                <span className="data-span-font">2000<span className="data-span-unit">+</span></span>
                <span className="data-span1">商家入驻</span>
              </span>
            </div>
            <div className="data-data">
              <span className="data-span">
                <span className="data-span-font">20<span className="data-span-unit">万+</span></span>
                <span className="data-span1">月度订单</span>
              </span>
            </div>
            <div className="data-data">
              <span className="data-span">
                <span className="data-span-font">20<span className="data-span-unit">+</span></span>
                <span className="data-span1">覆盖省份</span>
              </span>
            </div>
            <div className="data-data">
              <span className="data-span">
                <span className="data-span-font">10<span className="data-span-unit">万+</span></span>
                <span className="data-span1">在租商品</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* 消费者业务 */}
      <div className="consumption">
        <div className="consumption-title">
          消费者业务
        </div>
        <hr className="hr-orange" />
        <div className="consumption-title-bottom">
          为消费者提供免押金租物服务，让生活更简单
        </div>
        <div className="consumption-content">
          <div className="consumption-item" id="video">
            <div className="consumption-item-title">宅家游戏电玩</div>
            <div className="consumption-item-btn" onClick={changeLeaseShow}>查看详情</div>
          </div>
          <div className="consumption-wide-content">
            <div className="consumption-wide-item" id="brand">
              <div className="consumption-wide-item-title">大牌手机抢先租</div>
              <div className="consumption-wide-item-btn" onClick={changeLeaseShow}>查看详情</div>
            </div>
            <div className="consumption-wide-item" id="camera">
              <div className="consumption-wide-item-title">摄影旅拍助手</div>
              <div className="consumption-wide-item-btn" onClick={changeLeaseShow}>查看详情</div>
            </div>
          </div>
          <div className="consumption-item" id="computer">
            <div className="consumption-item-title">精选办公电脑</div>
            <div className="consumption-item-btn" onClick={changeLeaseShow}>查看详情</div>
          </div>
          <div className="consumption-item" id="watch">
            <div className="consumption-item-title">耳机手表配饰</div>
            <div className="consumption-item-btn" onClick={changeLeaseShow}>查看详情</div>
          </div>
          <div className="consumption-item" id="pad">
            <div className="consumption-item-title">平板电脑合集</div>
            <div className="consumption-item-btn" onClick={changeLeaseShow}>查看详情</div>
          </div>
        </div>
        <div className="consumption-footer">全行业全场景助你业务升级</div>
        <div className="consumption-footer-info">只要能租的行业/产品，我们都为你提供解决</div>
        <Modal
          visible={leaseShow}
          onCancel={canleLeaseShow}
          footer={null}
          className="modalLease"
          getContainer={false}
        >
          <div className="title">我要租赁</div>
          <div className="icon">
            <div className="icon-pic"></div>
            <div className="icon-title">支付宝扫一扫，立即租赁</div>
          </div>

        </Modal>
      </div>
      {/* 三大核心产品 */}
      <div className="product">
        <div className="product-title">
          三大核心产品
        </div>
        <hr className="hr-orange" />
        <div className="product-title-bottom">
          一站式、支付、流量、免押金风控、司法，你需要的我们都有
        </div>
        <div className="product-icon" >
          <div className="product-icon-box" onMouseOver={() => setChannel(1)}>
            <div className={"product-icon-box-icon " + (channel === 1 ? "bussiness-active" : "bussiness")}></div>
            <div className="product-icon-box-name">商家入驻</div>
          </div>
          <div className="product-icon-box" onMouseOver={() => setChannel(2)}>
            <div className={"product-icon-box-icon " + (channel === 2 ? "mini-active" : "mini")}></div>
            <div className="product-icon-box-name">租赁小程序</div>
          </div>
          <div className="product-icon-box" onMouseOver={() => setChannel(3)}>
            <div className={"product-icon-box-icon " + (channel === 3 ? "open-active" : "open")}></div>
            <div className="product-icon-box-name">开放平台</div>
          </div>
        </div>
        <div className="product-content-store" id="storeContent" style={{ display: channel === 1 ? "block" : "none" }}>
          <div className="product-content-info">
            <span className="product-content-title">
              租赁商0元免费入驻
            </span>
            <ul className="product-contents">
              <li><div className="orangeCircle"></div> 0成本，1分钟免费快速开店</li>
              <li><div className="orangeCircle"></div> 线上，线下，微信，支付宝等流量全渠道通用</li>
              <li><div className="orangeCircle"></div> 导购选品，免押下单，租金扣款，逾期追款一站式闭环</li>
              <li><div className="orangeCircle"></div> 手机数码/母婴图书/服饰包箱/汽车电动车/美妆个护等全品类通用</li>
            </ul>
            <div className="product-button">
              <a className="product-button-left" href="#lianxi">
                <a href="#lianxi">免费试用</a>
              </a>
              <div className="product-button-right">
                <span onClick={changePages}>查看详情</span>
              </div>
            </div>
          </div>
        </div>
        <div className="product-content-store" style={{ display: channel === 2 ? "block" : "none" }} id="appContent">
          <div className="product-content-info">
            <span className="product-content-title">
              您自己的独立品牌
            </span>
            <ul className="product-contents">
              <li><div className="orangeCircle"></div> 基于云服务的支付宝小程序</li>
              <li><div className="orangeCircle"></div> 低成本享有租物流量红利</li>
              <li><div className="orangeCircle"></div> 无需自建研发团队，低成本快速拥有</li>
            </ul>
            <div className="product-button">
              <a className="product-button-left" href="#lianxi">
                <a href="#lianxi">免费试用</a>
              </a>
              <div className="product-button-right">
                <span onClick={changePages}>查看详情</span>
              </div>
            </div>
          </div>
        </div>
        <div className="product-content-store" style={{ display: channel === 3 ? "block" : "none" }} id="openContent">
          <div className="product-content-info">
            <span className="product-content-title">
              更多功能供您使用
            </span>
            <ul className="product-contents">
              <li><div className="orangeCircle"></div> 多重技术能力输出</li>
              <li><div className="orangeCircle"></div> 有效赋能商家把控风险</li>
              <li><div className="orangeCircle"></div> 安全高效经营无忧</li>
            </ul>
            <div className="product-button">
              <div className="product-button-left">
                <a href="#lianxi">免费试用</a>
              </div>
              <div className="product-button-right">
                <span onClick={changePages}>查看详情</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 四重保障部分 */}
      <div className="guarantee">
        <div className="guarantee-title">
          惠租四重保障
        </div>
        <hr className="hr-orange" />
        <div className="guarantee-title-bottom">
          全方位为你的资产保驾护航
        </div>
        <div className="guarantee-content">
          <div className="guarantee-item">
            <img src='https://oss.huizustore.com/9594f53b75ae45a0931ea20013c691d1.png' alt="img" className="guarantee-item-img" />
            <div className="guarantee-item-detail">
              <div className="guarantee-item-detail-title">免押风控</div>
              <div className="guarantee-item-detail-info">接入支付宝信用支付体系，为用户减免押金的同时解决商户的发货信任问题。结合惠租自研红盾风控，提供超1000条风控信息及AI人工建议，快速甄别用户资质，把控风险，稳健经营。</div>
            </div>
          </div>
          <div className="guarantee-item">
            <img src='https://oss.huizustore.com/67090c8c5b324f3fa8debc4a429a5655.png' alt="img" className="guarantee-item-img" />
            <div className="guarantee-item-detail">
              <div className="guarantee-item-detail-title">司法仲裁</div>
              <div className="guarantee-item-detail-info">与仲裁委系统级对接，案件证据一键提交审理，从提交至判决低至一个月时间内;同时可采用法院强制执行，失信人等多维司法途径保障资产安全。</div>
            </div>
          </div>
          <div className="guarantee-item">
            <img src='https://oss.huizustore.com/a658e609a70f4f41bd624b866105782d.png' alt="img" className="guarantee-item-img" />
            <div className="guarantee-item-detail">
              <div className="guarantee-item-detail-title">区块链及保险</div>
              <div className="guarantee-item-detail-info">永久存证，不可篡改；可购买人保，财产损失后保险赔偿。</div>
            </div>
          </div>
          <div className="guarantee-item">
            <img src='https://oss.huizustore.com/fcd729e333fe4262bf060a50e337d37b.png' alt="img" className="guarantee-item-img" />
            <div className="guarantee-item-detail">
              <div className="guarantee-item-detail-title">锁机</div>
              <div className="guarantee-item-detail-info">惠租推出的苹果锁机服务，贴合商家痛点，针对性地解决手机租赁逾期坏账问题。</div>
            </div>
          </div>
        </div>
      </div>
      {/* 联系我们 */}
      <div className="contact" id="lianxi">
        <div className="contact-title">
          联系我们
        </div>
        <div className="contact-content">
          <div className="contact-content-pop">
            <div className="contact-content-pop-title">提交步骤</div>
            <div className="contact-content-pop-content">拨打咨询电话，立即 领取开店大礼包</div>
          </div>
          <div className="contact-content-contact">
            <div className="contact-content-contact-icon"></div>
            <span className="contact-content-contact-phone">0571—26881669</span>
            <span className="contact-content-contact-time">9:30 - 18:00</span>
          </div>
          <div className="contact-form">
            <div className="contact-form-title">立即预约</div>
            <Form name="basic" onFinish={onFinish} form={form}>
              <Form.Item name="contactName" rules={[{ required: true, message: '您的姓名为必填' }]} style={{ marginTop: 30 }}>
                <div className="form-input">
                  <Input type="text" className="input-style" placeholder="请输入您的姓名" />
                </div>
              </Form.Item>
              <Form.Item name="enterpriseName" rules={[{ required: true, message: '企业名称为必填' }]}>
                <div className="form-input">
                  <Input type="text" className="input-style" placeholder="请输入企业名称" />
                </div>
              </Form.Item>
              <Form.Item name="telephone" rules={[{ required: true, message: '手机号码为必填' }]}>
                <div className="form-input">
                  <Input type="text" className="input-style" placeholder="请输入手机号码" />
                </div>
              </Form.Item>
              <Form.Item name="categories">
                <div className="form-input">
                  <Input type="text" className="input-style" placeholder="请输入意向类目，如：手机" />
                </div>
              </Form.Item>
              <button htmlType="submit" className="form-button">提交信息</button>
            </Form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default IndexContent;