import React, { useState } from 'react';
import './index.less';
import Footer from '../../../compontents/footer/index';
import HeaderNews from '../../../compontents/headerNews';
import InquiryModal from '../../../compontents/inquiryModal';
import { Carousel } from 'antd';
import BusinessModal from '../../../compontents/businessModal';
function Lock(props) {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalShow, setIsModalShow] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleClick = () => {
    setIsModalVisible(true)
  }

  const cancelModal = () => {
    setIsModalShow(false)
  }

  const showModal = () => {
    setIsModalShow(true)
  }

  const headerChangePages = (num) => {
    window.location.href = `${window.location.origin}${window.location.pathname}?flag=${num}`
  }

  return (
    <div className="pcContent">
      <HeaderNews />
      <InquiryModal visible={isModalVisible} handleCancel={handleCancel} />
      <BusinessModal visible={isModalShow} handleCancel={cancelModal} />
      <Carousel  dots={{className: 'swiper-dots'}} dotPosition="bottom" autoplay={true} autoplaySpeed={5000} >
        <div className="pcContent-container" >
          <div className="pcContent-container-header" id="lock-header" >
            <div className="container">
              <div className="pcContent-container-header-top">
                锁机服务
              </div>
              <div className="pcContent-container-header-center">
                多种业务模式均可支持
              </div>
              <div className="pcContent-container-header-bottom" href="lianxi" onClick={handleClick}>
                <a href="#lianxi">立即咨询</a>
              </div>
            </div>
          </div>
        </div>
        <div className="pcContent-container" onClick={() => headerChangePages(1)}>
          <div className="pcContent-container-header" id="total-header" style={{ cursor: 'pointer'}}>
            <div className="container">
              <div className="pcContent-container-header-top">
                全品类信用免押金租赁平台
              </div>
              <div className="pcContent-container-header-center">
                为消费者和商家提供更高效更智能的租赁服务
              </div>
              <div className="pcContent-container-header-bottom" href="lianxi">
                <a href="#lianxi">商家入驻</a>
              </div>
            </div>
          </div>
        </div>
        <div className="pcContent-container"  onClick={() => headerChangePages(2)}>
          <div className="pcContent-container-header" id="business-header" style={{ cursor: 'pointer'}}  >
            <div className="container">
              <div className="pcContent-container-header-top">
                商家入驻
              </div>
              <div className="pcContent-container-header-center">
                一分钟免费拥有自己的租赁店铺
              </div>
              <div className="pcContent-container-header-bottom" href="lianxi">
                <a href="#lianxi">立即试用</a>
              </div>
            </div>
          </div>
        </div>
        <div className="pcContent-container" onClick={() => headerChangePages(3)} >
          <div className="pcContent-container-header" id="program-header" style={{ cursor: 'pointer'}}>
            <div className="container">
              <div className="pcContent-container-header-top">
                租赁小程序
              </div>
              <div className="pcContent-container-header-center">
                您自己品牌的独立支付宝小程序
              </div>
              <div className="pcContent-container-header-bottom" href="lianxi" >
                <a href="#lianxi">立即咨询</a>
              </div>
            </div>
          </div>
        </div>
        <div className="pcContent-container" onClick={() => headerChangePages(4)} >
          <div className="pcContent-container-header" id="platform-header" style={{ cursor: 'pointer'}} >
            <div className="container">
              <div className="pcContent-container-header-top">
                开放平台
              </div>
              <div className="pcContent-container-header-center">
                有效帮助商家快速甄别用户资质，有效把控风险，<br />保证稳健经营。
              </div>
              <div className="pcContent-container-header-bottom" href="lianxi" >
                <a href="#lianxi">立即咨询</a>
              </div>
            </div>
          </div>
        </div>
      </Carousel>


      <div className="box" id="lock-service">
        <div className="box-title">服务介绍</div>
        <div className="box-des">锁机服务带来的安心和保障，对于店铺的营业额及发货量的提升效果显著</div>
        <div className="container">
          <img src="https://oss.huizustore.com/6052615c43fd4824bee9caffdca0720e.png" alt="img" />
          <div className="item-box">
              <div className="item-content">此惠租平台的锁机服务精准切合商家痛点，监管机能够最大限度将撸机党、逾期、坏账问题降到最低。</div>
              <div className="item-btn" onClick={showModal}>免费试用</div>
          </div>
          </div>
      </div>

      <div className="box" id="service-strength">
        <div className="box-title">服务优势</div>
        <div className="box-des">多种业务模式均可支持</div>
        <div className="container">
          <div className="strength-item">
            <img src="https://oss.huizustore.com/a48c3c7b402b416e8a0854e8d67bb5fe.png" alt="img"  className="strength-item-img"/>
            <div className="strength-item-detail">
              <div className="strength-item-detail-title">发货无忧</div>
              <div className="strength-item-detail-content">锁机服务精准切合商家痛点，发货监管机可以最大限度将撸机党和逾期坏账问题降到最低。</div>
            </div>
          </div>
          <div className="strength-item">
            <img src="https://oss.huizustore.com/6bc8573ea90e4be7913f99449f79d2d6.png" alt="img"  className="strength-item-img"/>
            <div className="strength-item-detail">
              <div className="strength-item-detail-title">操作简单</div>
              <div className="strength-item-detail-content">四步简单配置，最快3个工作日完成锁机服务配置，线上进行管理和设置。</div>
            </div>
          </div>
          <div className="strength-item">
            <img src="https://oss.huizustore.com/bf717ebc1917461e946255f318741d80.png" alt="img"  className="strength-item-img"/>
            <div className="strength-item-detail">
              <div className="strength-item-detail-title">效益倍增</div>
              <div className="strength-item-detail-content">锁机服务带来的安心保障，店铺的营业额及发货量的提升效果显著。</div>
            </div>
          </div>
        </div>
      </div>

      <div className="box" id="service-process">
        <div className="box-title">服务流程</div>
        <div className="box-des">采用按需购买锁机次数的形式，最快3-7个工作日就能完成服务配置，线上进行锁机管理和设置</div>
        <div className="container">
          <div className="process-item" id="process-one">
            <div className="process-item-title">购买服务</div>
            <div className="process-item-content">专业人员协助一对一开通</div>
          </div>
          <div className="process-item" id="process-two">
            <div className="process-item-title">开通账户</div>
            <div className="process-item-content">快速分配独立MDM账号</div>
          </div>
          <div className="process-item" id="process-three">
            <div className="process-item-title">设备配置</div>
            <div className="process-item-content">便捷配置独立监管锁</div>
          </div>
          <div className="process-item" id="process-four">
            <div className="process-item-title">无忧发货</div>
            <div className="process-item-content">在线接单，快速无忧出货</div>
          </div>
          <div className="process-item" id="process-five">
            <div className="process-item-title">锁机管理</div>
            <div className="process-item-content">服务限制/定位查看等一应俱全</div>
          </div>
        </div>
      </div>

      <div className="box" id="lock-online">
        <div className="box-title">线上操作</div>
        <div className="box-des">线上设置上锁状态更方便</div>
        <div className="container">
        <div className="lock-item">
            <img src="https://oss.huizustore.com/a07cc8c84a85491dbcb14595b9a8a312.png" alt="icon" className="lock-item-icon" id="lock-img" />
            <div>
              <div className="lock-item-title">上锁设备</div>
              <div className="lock-item-content">点击设备列表的丢失按钮，自定义提示文案，二次确认后即可进行锁机。</div>
            </div>
          </div>
          <div className="line"></div>
          <div className="lock-item">
            <img src="https://oss.huizustore.com/19dc76c46e914c2f902a7ca85f7fd6e6.png" alt="icon" className="lock-item-icon" id="unlock-img" />
            <div>
              <div className="lock-item-title">解锁设备</div>
              <div className="lock-item-content">点击列表的找回按钮，二次确认后，完成设备的解锁。</div>
            </div>
          </div>
          <div className="line"></div>
          <div className="lock-item" id="last">
            <img src="https://oss.huizustore.com/0d24cbfc5fa64a9f8be4da227cf066f3.png" alt="icon" className="lock-item-icon" id="free-img" />
            <div>
              <div className="lock-item-title">释放设备</div>
              <div className="lock-item-content">按顺序依次操作解除监管后，用户恢复出厂设置后即解除监管，完成设备释放。</div>
            </div>
          </div>
        </div>
      </div>


      

      <Footer />
    </div>
  )
}

export default Lock;