import React,{useState,useEffect} from 'react';
import { Pagination } from 'antd';
import { Tabs, Input } from 'antd-mobile'
import axios from 'axios';
import './index.less'

function NewsContent(props) {

  const [newsData, setNewsData] = useState([]);
  const [tabActiveKey, setTabActiveKey] = useState('1');
  // const [hasMore, setHasMore] = useState(false);
  // const [loading, setLoading] = useState(false)
  const [current, setCurrent] = useState(0)
  const [total, setTotal] = useState(0)
  
  useEffect(()=>getNews(0,1),[])

  const getNews = (category,pageNumber,searchTitle = undefined) =>{ 
    // setLoading(true)
    axios({
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'GET',
      url: searchTitle ? `/consumer-zhifubao/news/center?newsCategory=${category}&pageSize=5&pageNumber=${pageNumber}&searchTitle=${searchTitle}` : `/consumer-zhifubao/news/center?newsCategory=${category}&pageSize=5&pageNumber=${pageNumber}`,
      responseType: 'json',
    }).then((res)=>{
      if(res?.data?.code===1){
        setNewsData(res.data.data.page.records)
        setCurrent(res.data.data.page.current)
        setTotal(res.data.data.page.total)
      }
    })
  }

  // const loadMore = () => {
  //   const num = parseInt(newsData.length/10)+1 
  //   setLoading(true)
  //   axios({
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     method: 'GET',
  //     url: `/consumer-zhifubao/news/center?newsCategory=${tabActiveKey-1}&pageSize=10&pageNumber=${num}`,
  //     responseType: 'json',
  //   }).then((res)=>{
  //     if(res?.data?.code===1){
  //       setNewsData(...newsData, ...res.data.data.page.records)
  //       setCurrent(res.data.data.page.current)
  //       setTotal(res.data.data.page.total)
  //       setHasMore(res.data.data.page.records.length > 0)
  //     }
  //   }).finally(() => setLoading(false))
  // }

  const onTabChange=key=>{
    setTabActiveKey(key)
    getNews(key-1,1)
  }

  const pageChange = num => {
    getNews(tabActiveKey - 1, num)
  }


  return (
    <div className='newsCenter'>
      <div className='newsCenter-banner'>
          <div className='newsCenter-banner-title'>新闻中心</div>
          <div className="newsCenter-banner-input">
            <div className="icon"></div>
            <Input className="content" placeholder="您想要了解的行业动态" onEnterPress={(e) => {
              getNews(tabActiveKey-1,1,e.target.value)
            }} />
          </div>
      </div>
      <Tabs 
      className='newsCenter-newsTabs'
      defaultActiveKey={tabActiveKey}
      onChange={onTabChange}>
        <Tabs.Tab className='newsCenter-newsTabs-newsTabsPane' title='行业动态' key='1'>
        </Tabs.Tab>
        <Tabs.Tab title='公司新闻' key='2'>
          </Tabs.Tab>
        <Tabs.Tab title='商家案例' key='3'>
          </Tabs.Tab>
        <Tabs.Tab title='媒体报道' key='4'>
        </Tabs.Tab>
        {/* <Tabs.Tab title='租机干货' key='5'>
        </Tabs.Tab> */}
      </Tabs>
      <div className='newsCenter-container'>
      {!!newsData.length && newsData.map((item,index)=>
        <div>
          <a key={index} className='newsCenter-container-content' href={item.url} target="_blank" rel="noreferrer">
            <div className='newsCenter-container-content-info'>
              <h6 className='newsCenter-container-content-info-title'>{item.title}</h6>
              <div className='newsCenter-container-content-info-date'>{item?.updateTime?item.updateTime:item.createTime}</div>
            </div>
            <div className='newsCenter-container-content-img'><img src={item.pictureUrl} alt='img'/></div>
          </a>
          <div className="line"></div>
        </div>
        )}
         {/* <InfiniteScroll loadMore={loadMore} hasMore={hasMore} /> */}
      </div>
      <Pagination className='newsPage' current={current} total={total} pageSize={5} onChange={pageChange}></Pagination>
    </div>
  )
}

export default NewsContent;